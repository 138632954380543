import * as React from "react"
import PageLayout from "../../components/pageLayout";
import Seo from "../../components/seo";

const RomvesenCloudPage = () => {
    return <PageLayout>
        <Seo title={"Romvesen Cloud"}/>
        <div className="relative py-16 bg-secondary-light overflow-hidden">
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose mx-auto">
                    <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Tjenester:
            </span>
                        <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-100 sm:text-4xl">
              Romvesen Cloud
            </span>
                    </h1>
                </div>
                <div className="mt-6 prose prose-primary prose-lg text-gray-200 mx-auto">
                    <p>
                        ROMVESEN-CLOUD er en egen skytjeneste vi har utviklet for kundene våre. Dette er en tjeneste
                        som kan behandle store mengder data på en systematisert og forståelig måte som gjør dataen
                        anvendelig for effektiv drift innen de respektive bransjene. Skytjenesten tilbyr en enkel og forståelig
                        visuell presentasjon av den aktuelle mengden med data, der man blant annet får innsikt i parametere som dybde, størrelse og koordinater for funn. Det er mulighet for at brukeren selv kan gjøre sine notater direkte i systemet, knytt til ulike funn. Relevant data vil enkelt kunne eksporteres, for eksempel som en PDF-presentasjon for deling i et team. Datasett kan enkelt importeres og
                        eksporteres fra systemet og man kan enkelt dele data med samarbeidspartnere. Alt dette gjør at man kan handle effektivt for optimalisert drift innen de respektive bransjene.
                    </p>
                </div>
            </div>
        </div>
    </PageLayout>
}

export default RomvesenCloudPage